import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class ItemGroups {
  constructor () {
    const base = axios.create({
      // using base items api
      baseURL: process.env.VUE_APP_ITEMS_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.itemGroups = base
  }

  async getGroupsByItem (source, item_id, offset = 0, limit = 50) {
    await AuthToken.setAuth(this.itemGroups, {
      audience: process.env.VUE_APP_ITEM_AUDIENCE
    })

    return this.itemGroups({
      url: `/item-groups`,
      method: 'GET',
      params: { source, item_id, offset, limit }
    })
  }

  async* getGroupItems (groupId, active = true, offset = 0, limit = 200) {
    await AuthToken.setAuth(this.itemGroups, {
      audience: process.env.VUE_APP_ITEM_AUDIENCE
    })

    let page = 1
      
     while (true) {
      offset = (page * limit) - limit
  
      const res = await this.itemGroups({
        url: `/item-group/${groupId}/items`,
        method: 'GET',
        params: { active, offset, limit }
      })

      if (!res.data || res.data.length === 0) {
        break
      }
    
      yield res.data
    
      if (res.data.length < limit) break
      page++
    }
  }
}

export default new ItemGroups()
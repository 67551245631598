<template>
  <v-dialog
    :value="value"
    max-width="1300"
    width="1300"
    persistent>
    <v-card tile flat>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-card-title>
            {{ readOnlyPromo ? 'Related Items' : 'Add Related Items' }}
          </v-card-title>
        </v-toolbar-title>
        <v-select
          v-model="groupId"
          label="Item Group"
          :items="groups"
          item-text="group_name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          style="max-width: 35%"
          class="ml-2"
          hide-details
          outlined
          dense>
        </v-select>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!readOnlyPromo"
          small
          :disabled="(localAddedItems.length === 0 && removedItems.length === 0) || adding"
          :loading="adding"
          color="primary"
          @click="add">
          {{ itemSearch ? 'Save Changes' : 'Add Items' }}
        </v-btn>
        <v-btn
          small
          text
          class="ml-4"
          @click="updateOnClose ? $emit('close', true) : $emit('cancel')">
          Cancel
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
       <v-container fluid class="ma-0 pa-0">
        <v-data-table
          :height="tableSize"
          :headers="headers"
          :items.sync="relatedItems"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [-1]}"
          :loading="loading"
          fixed-header
          calculate-widths
          sort-by="item_id"
          dense>
          <template v-slot:[`header.included`]>
            <v-simple-checkbox
              v-if="!readOnlyPromo"
              dark
              :disabled="(relatedItems.length === 0 || includeAllDisabled)"
              v-model="includeAll"
              dense
              :ripple="false">
            </v-simple-checkbox>
          </template>

          <template v-slot:[`item.included`]="{ item }">
            <template v-if="!isIncludedOnPromo(item)">
              <v-simple-checkbox
                v-if="!readOnlyPromo"
                color="primary"
                v-model="item.selected"
                @input="itemSelected($event, item)"
                :disabled="!isAvailableItem(item)"
                dense
                :ripple="false">
              </v-simple-checkbox>
            </template>
            <v-icon v-else color="success">
              mdi-check
            </v-icon>
          </template>

          <template v-slot:[`item.cost`]="{ item }">
            <v-tooltip top :disabled="item.item.cost == item.item.effective_cost">
              <template v-slot:activator="{ on }">
                <span 
                  v-on="on"
                  v-bind:class="(item.item.cost != item.item.effective_cost) ? 'primary--text font-weight-bold' : ''">
                  {{item.item.effective_cost}}
                </span>
              </template>
              This item has a cost change on {{ $config.formatDate(item.item.future_cost_date) }} <br>
              The current cost is {{ item.item.cost }} <br>
              This promotion will use the new cost of {{ item.item.effective_cost }}
            </v-tooltip>
          </template>
        </v-data-table>
       </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
// API
import ItemGroups from '@/axios/item-group-endpoint'
import PromoItem from '@/axios/promotion-item-endpoint'
// Mixins
import { displayAlert } from '@/mixins/alert'
import { fullWidthTable } from '@/mixins/table'
import { utils } from '@/mixins/utils'
import { userAccess } from '@/mixins/user-access'
export default {
  data () {
    return {
      relatedItems: [],
      groups: [],
      groupId: null,
      localAddedItems: [],
      includeAll: false,
      adding: false,
      loading: false,
      headers: [
        { text: '', align: 'center', value: 'included', sortable: false, filterable: false, class: 'accent white--text' },
        { text: 'Source', sortable: false, value: 'source_name', class: 'accent white--text' },
        { text: 'Item Id', sortable: false, value: 'item_id', class: 'accent white--text' },
        { text: 'Description', sortable: false, value: 'item.description', class: 'accent white--text' },
        { text: 'UPC', sortable: false, value: 'item.upc', class: 'accent white--text' },
        { text: 'Vendor Id', sortable: false, value: 'item.vendor_id', class: 'accent white--text' },
        { text: 'Vendor Name', sortable: false, value: 'item.vendor_name', class: 'accent white--text' },
        { text: 'Retail Price', sortable: false, value: 'item.sale_price', class: 'accent white--text' },
        { text: 'Cost', sortable: false, value: 'cost', class: 'accent white--text' },
        { text: 'Pack Size', sortable: false, value: 'item.pack_size', class: 'accent white--text' }
      ],
      updateOnClose: false
    }
  },
  name: 'RelatedItemsModal',
  mixins: [displayAlert, fullWidthTable, utils, userAccess],
  props: {
    value: Boolean,
    item_sources: Array,
    promoItem: Object,
    promoItems: Array,
    addedItems: Array,
    tableItems: Array,
    promoItemGroup: [Object, undefined],
    vendorIds: {
      type: Array,
      default: () => ([])
    },
    readOnlyPromo: {
      type: Boolean,
    },
    promo: Object
  },
  watch: {
    groupId: {
      handler (newValue) {
        if (newValue != null) {
          this.getRelatedItems()
        }
      }
    },
    includeAll: {
      handler (newValue) {
        if (newValue === false && this.availableItems.some(item => !this.isAddedLocally(item))) {
          return
        }
        this.relatedItems = this.relatedItems.map(item => {
          if (this.isAvailableItem(item)) {
            item.selected = newValue
            this.itemSelected(newValue, item)
          }
          return item
        })
      }
    },
  },
  async created () {
    this.nonTableHeight = 350
    if (this.promoItem) {
      await this.getItemGroups()
    }
  },
  computed: {
    availableItems () {
      return this.relatedItems.filter(item => this.isAvailableItem(item))
    },
    includeAllDisabled () {
      return (this.availableItems.length === 0)
    },
    itemSearch () {
      return !this.promoItem.item
    },
    removedItems () {
      return this.relatedItems.flatMap(item => {
        return item && (this.isAdded(item) && !this.isTableItem(item) && !item.selected)
          ? item.item
          : []
      })
    }
  },
  methods: {
    async getItemGroups () {
      // item could be coming from promo items or item search
      const item = this.promoItem.item || this.promoItem
      this.loading = true
      let groups = []
      try {
        const res = await ItemGroups.getGroupsByItem(item.source, item.item_id)
        if (res?.data?.length > 0) {
          groups = res.data.filter(group => group?.group_type?.constant !== 'COMPONENT_ITEM')
        }
        this.groups = groups
        if (this.groups.length > 0) {
          if (!this.groupId) this.groupId = this.groups[0].id
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    async getRelatedItems () {
      if (!this.groupId) return
      this.loading = true
      let relatedItems = []
      try {
        for await (let results of ItemGroups.getGroupItems(this.groupId)) {
          if (results?.length > 0) {
            results = results.flatMap(groupItem => {
              return this.formatGroupItem(groupItem)
            })
            relatedItems.push(...results)
          }
        }
        if (this.vendorIds.length > 0) {
          relatedItems = relatedItems.filter(item => {
            return this.vendorIds.includes(item?.item?.vendor_id)
          })
        }
        this.relatedItems = relatedItems
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    formatGroupItem (groupItem) {
      groupItem.source_name = this.$store.getters.getSourceName(groupItem.source)
      groupItem.selected = this.isSelected(groupItem)
      groupItem.included = this.isIncludedOnPromo(groupItem)
      if (groupItem?.item) {
        groupItem.item.effective_cost = this.calcEffectiveCost(groupItem.item, this.promo)
        groupItem.item = this.formatItemPrices(groupItem.item)
      }
      return groupItem
    },
    isSelected (item) {
      return this.isAdded(item) || this.isAddedLocally(item)
    },
    isSameItem (item1, item2) {
      return (item1?.item_id === item2.item_id) && (item1?.source === item2.source)
    },
    isIncludedOnPromo (item) {
      return this.promoItems.some(promoItem => {
        return this.isSameItem((promoItem?.item || promoItem), item)
      })
    },
    itemSelected (value, item) {
      if (value === true) {
        if (!this.isAddedLocally(item)) {
          this.localAddedItems.push(item)
        }
      } else {
        const index = this.localAddedItems.findIndex(p => {
          return (p?.item_id === item.item_id) && (p?.source === item.source)
        })
        if (index > -1) this.localAddedItems.splice(index, 1)
        if (this.includeAll) {
          this.includeAll = false
        }
      }
    },
    isAddedLocally (item) {
      return Boolean(this.localAddedItems.find(i => this.isSameItem(i, item)))
    },
    isAdded (item) {
      return Boolean(this.addedItems?.find(i => this.isSameItem(i, item)))
    },
    isAvailableItem (item) {
      return !this.isIncludedOnPromo(item) && !(this.isTableItem(item) && this.isAdded(item))
    },
    isTableItem (item) {
      return Boolean(this.tableItems?.find(i => this.isSameItem(i, item)))
    },
    add () {
      if (this.itemSearch) {
        const addedItems = this.localAddedItems.flatMap(groupItem => {
          if (!this.isAdded(groupItem)) {
            groupItem.item.linked_from = {
              item_id: this.promoItem.item_id,
              source: this.promoItem.source
            }
            return groupItem.item
          }
          return []
        })
        this.$emit('updateAdded', { addedItems, removedItems: this.removedItems })
        this.$emit('cancel')
      } else {
        this.addItems()
      }
    },
    async addItems () {
      this.adding = true
      const promises = this.localAddedItems.map(item => {
        const payload = this.buildPromoItemPayload(item)
        return PromoItem.post(payload)
      })
      try {
        const { rejected } = await this.getAllSettled(promises, true)
        if (rejected.length > 0) throw rejected
        this.emitAlert(true, 'success', 'Items added successfully', [], true)
        this.$emit('updateHistory')
        this.$emit('close', true)
      } catch (err) {
        this.handleError(err)
      } finally {
        this.adding = false
      }
    },
    buildPromoItemPayload (item) {
      const itemGroupId = this.promoItemGroup.id
      let payload = {
        promo_item_group_id: itemGroupId,
        item_id: item.item_id,
        include_item: true,
        source: item.source
      }
      return payload
    }
  }
}
</script>